.HomeContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}
.GreetText {
  color: #707070;
  margin: unset;
}
.ImText {
  color: #ffffff;
  margin: unset;
}
.DevText {
  width: 500px;
  color: #707070;
  margin: unset;
  margin-top: 0;
  margin-bottom: 20px;
}
.ContactMeBtn {
  color: #ffdf6c;
  font-size: 25px;
  padding: 25px 75px;
  border: 1px solid #ffdf6c;
  text-transform: initial;
}
